::selection {
background-color: 35;
}
::-moz-selection {
background-color: 35;
}
body { background-color: #ffffff; }
body { color: var(--clr-90923); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-90923);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
}
h2 {
color: var(--clr-90923);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h3 {
color: var(--clr-90923);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 28px;

}
}
h4 {
color: var(--clr-90923);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 24px;

}
}
h5 {
color: var(--clr-90923);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-90923);
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-90923);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 14;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 24px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-90924);}
a:hover {color: var(--clr-90923);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-90925);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-90925);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-90925);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: var(--clr-90924);
&:hover { color: #ffffff;}
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-90924);
color: #ffffff;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-90924);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-90924);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Main Menu:6 */
nav.me-Menu.MES6 {
.menu-item.MEC6{background-color: #ffffff; &:hover {background-color: var(--clr-90924);}
}
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: var(--clr-90923);
text-transform: capitalize;
}
 &:hover > a.MEC6{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC6 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC6 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC6 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 6;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC6 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px 25px;}

& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC6:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-90926-flat);}}
&.vertical .menu-item.MEC6:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-90926-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC6{background-color: var(--clr-90926); &:hover {background-color: 25;}
}
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC6{color: 6;
}
 }

}
}
 }
/* menu bottom line:7 */
.MES7 {
& > hr {border-width: 0 0 1px 0;}
& > hr {border-color: transparent transparent var(--clr-90927) transparent;}
& > hr {border-bottom-style: solid;}
 }
/* :8 */
.MES8 {
background-color: #ffffff;
border-style: solid;
border-color: 19;
border-width: 5px;
padding: 15px;

.MEC8 {background-color: var(--clr-90924);color: #ffffff;
padding: 10px 30px;

font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
border-style: solid;
&:hover {background-color: 2;
color: #ffffff;
}
}
 }
/* drop down category:9 */
nav.me-Menu.MES9 {
.menu-item.MEC9{background-color: var(--clr-90928); &:hover {background-color: #ffffff;}
}
& .menu-item.MEC9, & .menu-item.MEC9 > div.MEC9{ & > a.MEC9{color: var(--clr-90923);
font-size: 14px;
text-transform: capitalize;
}
 &:hover > a.MEC9{color: var(--clr-90924);
}
 }
&.horizontal > .menu-item.MEC9 { border:0;
border-color: var(--clr-90927);
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC9 { border:0;
border-color: var(--clr-90927);
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC9 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC9 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 20px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Home Slider:10 */
.MES10 {
& .slider-arrow {background-color: 25;
color: #ffffff;
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slider-arrow:hover{background-color: 4;
color: #ffffff;
} }
/* Cart Qty Box:11 */
.MES11 {
background-color: var(--clr-90929);
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-90927);
 }
.MES11 {
background-color: var(--clr-90929);
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-90927);
 }
/* Alternate:12 */
.MES12 {
background-color: var(--clr-90930);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Alternate:13 */
.MES13 {
background-color: var(--clr-90930);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-90930);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
a.MEC13 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC13{
color: #ffffff;
}
/* Carousel Blocks:14 */
.MES14 {
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-90927);
 }
.MES14 {
padding: 20px 10px;

@media #{$medium-up} {
padding: 20px 30px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-90927);
 }
/* Carousel Button:15 */
.MES15 {
background-color: var(--clr-90924);
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 14.4px;
};
padding: 8px 14px;

 }
/* home product button:16 */
.MES16 {
background-color: var(--clr-90924);
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 5px 15px;

 }
/* home product add to cart button:17 */
.MES17 {
background-color: var(--clr-90925);
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 5px 15px;

 }
/* Product Blocks:18 */
.MES18 {
padding: 20px 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-90927);
 }
.MES18 {
padding: 20px 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-90927);
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: var(--clr-90924);
 }
 }
/* Page Spacer:19 */
.MES19 {
padding: 15px 0;

 }
/* Carousel Wrap:20 */
.MES20 {
border-width: 0 1px 0 0;
border-style: solid;
border-color: transparent var(--clr-90927) transparent transparent;
 }
.MES20 {
border-width: 0 1px 0 0;
border-style: solid;
border-color: transparent var(--clr-90927) transparent transparent;
 }
/* Home heading panel:21 */
.MES21 {
background-color: var(--clr-90929);
padding: 14px 25px;

border-width: 1px 1px 0 1px;
border-style: solid;
border-color: var(--clr-90927) var(--clr-90927) transparent var(--clr-90927);
 }
.MES21 {
background-color: var(--clr-90929);
padding: 14px 25px;

border-width: 1px 1px 0 1px;
border-style: solid;
border-color: var(--clr-90927) var(--clr-90927) transparent var(--clr-90927);
 }
/* FQA 1:23 */
details.MES23 {
& > summary{background-color: var(--clr-90924);
}
color: var(--clr-90923);
& > article { color: var(--clr-90923);
 }
& > summary{padding: 10px;}

& > article {border-width: 1px;
border-style: dashed;
border-color: #ffffff;
}& > summary {& > div  > i{color: #ffffff;
font-size: 14px;
}}
& > summary { color: #ffffff;
font-size:16.2px;
@media #{$medium-up} {
font-size:22.8px;
}
 }
& > article a { color: var(--clr-90924);
 }
& > article li { color: var(--clr-90923);
 }
 }
/* FQA 2:24 */
details.MES24 {
& > summary{background-color: var(--clr-90930);
}
color: var(--clr-90923);
& > article { color: var(--clr-90923);
 }
& > summary{padding: 10px;}

& > article {border-width: 1px;
border-style: dashed;
border-color: #ffffff;
}& > summary {& > div  > i{color: #ffffff;
font-size: 14px;
}}
& > summary { color: #ffffff;
font-size:16.2px;
@media #{$medium-up} {
font-size:22.8px;
}
 }
& > article a { color: var(--clr-90924);
 }
& > article li { color: var(--clr-90923);
 }
 }
/* FAQ 3:25 */
details.MES25 {
& > summary{background-color: #ffffff;
}
color: var(--clr-90923);
& > article { color: var(--clr-90923);
 }
& > summary{padding: 20px;}

& > article{padding: 15px 35px;}

& > summary{border-width: 0 0 1px 0;
border-style:solid;
border-color: transparent transparent var(--clr-90927) transparent;
}& > summary { color: var(--clr-90923);
font-size:16.2px;
@media #{$medium-up} {
font-size:22.8px;
}
 }
& > article a { color: var(--clr-90923);
&:hover { color: var(--clr-90923); }
 }
& > article li { color: var(--clr-90923);
 }
 }
/* Light:26 */
.MES26 {
background-color: var(--clr-90928);
color: var(--clr-90923);
 }
/* Light:27 */
.MES27 {
background-color: var(--clr-90928);
color: var(--clr-90923);
 }
.MES27 {
background-color: var(--clr-90928);
color: var(--clr-90923);
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: var(--clr-90923);
 }
 }
cite.MEC27{
color: var(--clr-90923);
}
/* Top Strip:28 */
.MES28 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://new.nobutts.com.au/img/1655/911');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
.MES28 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.4 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://new.nobutts.com.au/img/1655/911');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
/* footer menu:29 */
nav.me-Menu.MES29 {
& .menu-item.MEC29, & .menu-item.MEC29 > div.MEC29{ & > a.MEC29{color: var(--clr-90923);
font-size: 14px;
text-transform: uppercase;
}
  }
&.horizontal > .menu-item.MEC29 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC29 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC29 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC29 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* cart preview panel:30 */
.MES30 {
color: var(--clr-90923);
font-size: 11.2px;
 }
.MES30 {
color: var(--clr-90923);
font-size: 11.2px;
 }
cite.MEC30{
color: var(--clr-90923);
font-size: 11.2px;
}
/* Product Price Panel:31 */
.MES31 {
color: var(--clr-90924);
font-size: 14px;
@media #{$large-up} {
font-size: 25.2px;
};
 }
.MES31 {
color: var(--clr-90924);
font-size: 14px;
@media #{$large-up} {
font-size: 25.2px;
};
 }
cite.MEC31{
color: var(--clr-90924);
font-size: 14px;
@media #{$large-up} {
font-size: 25.2px;
};
}
/* cart item bottom border:32 */
.MES32 {
& > hr {border-width: 0 0 1px 0;}
& > hr {border-color: transparent transparent var(--clr-90927) transparent;}
& > hr {border-bottom-style: solid;}
 }
/* Paypal:33 */
.MES33 {
background-color: #ffffff;
color: var(--clr-90932);
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-90932);
 }
/* Cart Item Panel:34 */
.MES34 {
background-color: var(--clr-90929);
padding: 14px 0;

border-width: 1px 1px 0 1px;
border-style: solid;
border-color: var(--clr-90927) var(--clr-90927) transparent var(--clr-90927);
 }
.MES34 {
background-color: var(--clr-90929);
padding: 14px 0;

border-width: 1px 1px 0 1px;
border-style: solid;
border-color: var(--clr-90927) var(--clr-90927) transparent var(--clr-90927);
 }
/* Logo panel:35 */
.MES35 {
background-color: #ffffff;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 5px;

 }
.MES35 {
background-color: #ffffff;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 5px;

 }
/* Dark:36 */
.MES36 {
background-color: var(--clr-90933);
color: #ffffff;
 }
/* Dark:37 */
.MES37 {
background-color: var(--clr-90933);
color: #ffffff;
 }
.MES37 {
background-color: var(--clr-90933);
color: #ffffff;
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: #ffffff;
 }
 }
cite.MEC37{
color: #ffffff;
}
/* Accordion:38 */
details.MES38 {
& > summary{background-color: transparent;
}
 }
/* Shade 3:39 */
.MES39 {
background-color: transparent;
 }
/* Shade 3:40 */
.MES40 {
background-color: transparent;
 }
.MES40 {
background-color: transparent;
 }
/* Highlight:41 */
.MES41 {
background-color: var(--clr-90934);
 }
/* Highlight:42 */
.MES42 {
background-color: var(--clr-90934);
 }
.MES42 {
background-color: var(--clr-90934);
 }
/* Header colour:43 */
.MES43 {
background-color: var(--clr-90925);
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 5px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
 }
.MES43 {
background-color: var(--clr-90925);
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 5px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #ffffff;
 }
 }
a.MEC43 { color: #ffffff;
&:hover { color: var(--clr-90924);}
 }
cite.MEC43{
color: #ffffff;
}
/* Header colour:44 */
.MES44 {
background-color: var(--clr-90930);
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 5px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
 }
.MES44 {
background-color: var(--clr-90930);
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
border-width: 5px 0 0 0;
border-style: solid;
border-color: #ffffff transparent transparent transparent;
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: #ffffff;
 }
 }
a.MEC44 { color: #ffffff;
&:hover { color: var(--clr-90924);}
 }
cite.MEC44{
color: #ffffff;
}
/* Cart size:45 */
.MES45 {
color: #ffffff;
font-size: 21px;
@media #{$medium-up} {
font-size: 28px;
};
 }
.MES45 {
color: #ffffff;
font-size: 21px;
@media #{$medium-up} {
font-size: 28px;
};
 }
cite.MEC45{
color: #ffffff;
font-size: 21px;
@media #{$medium-up} {
font-size: 28px;
};
}
/* Form Button:46 */
.MES46 {
background-color: var(--clr-90930);
&:hover {background-color: var(--clr-90923);}
color: #ffffff;
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
padding: 5px 15px;

 }
